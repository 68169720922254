import React from 'react'

function End() {
  return (
    
      <span className="scroll-top-arrow">
        <i className="fas fa-angle-up"></i>
      </span>
  );
}

export default End
